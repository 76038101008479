import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import BlogCard from '@system/blog-card'
import { imgMaxWidths } from '@variables/global-variables'

const useStyles = makeStyles((theme) => ({
  displayCards: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 8px',
    textAlign: (props) =>
      props.alignment == 'left' ? '' : `${props.alignment}`,
    width: '33.33333333%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: '15px',
    display: 'block',
  },
  description: {
    color: theme.palette.text.tertiary,
    paddingBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '15px',
    },
    display: 'block',
  },
  rssFeedDisplay: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const BlogReferences = (props) => {
  const staticQueryData = useStaticQuery(graphql`
    query {
      allFeedAccBlog(sort: { fields: isoDate, order: DESC }, limit: 6) {
        nodes {
          isoDate(fromNow: true)
          categories
          contentSnippet
          creator
          dc {
            creator
          }
          enclosure {
            length
            type
            url
          }
          guid
          id
          link
          title
          pubDate
          media {
            thumbnail {
              attrs {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { numberOfReferences } = props.blok
  const classes = useStyles()

  const rssFeedData =
    staticQueryData.allFeedAccBlog &&
    staticQueryData.allFeedAccBlog.nodes.slice(0, numberOfReferences)

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.rssFeedDisplay}>
        {!!rssFeedData &&
          rssFeedData.map((resource, i) => {
            return (
              <Box
                key={resource.id + i}
                className={classNames(classes.displayCards)}
              >
                <BlogCard
                  textToHyperlink="Read the Blog"
                  target="_BLANK"
                  imgMaxWidth={imgMaxWidths.resourceCard}
                  {...resource}
                />
              </Box>
            )
          })}
      </Box>
    </SbEditable>
  )
}

export default BlogReferences
